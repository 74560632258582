export enum TruckDocumentType {
  TAX_FILE,
  FITNESS_CERTIFICATE_FILE,
  ROUTE_PERMIT_FILE,
  CASE_FILE,
  OTHERS,
  OTHER_FILE,
  TRUCK_IMAGE,
  REG_PAPER_FILE,
  INSURANCE_FILE,
}
